/** @jsx jsx */
import { jsx } from "theme-ui"
import { Component } from "react"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"

import Tab from "./tab"

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    onTabChange: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props)

    this.state = {
      activeTabId: kebabCase(this.props.children[0].props.title),
    }
  }
  onClickTabItem = tab => {
    this.setState({ activeTabId: tab.id })
    this.props.onTabChange(tab)
  }
  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTabId },
    } = this

    return (
      <div>
        <ol
          sx={{
            display: "flex",
            justifyContent: "space-between",
            listStyle: "none",
            mx: 0,
          }}
        >
          {children.map(child => {
            const { title, id, customData } = child.props

            return (
              <Tab
                activeTabId={activeTabId}
                key={kebabCase(title)}
                id={id}
                title={title}
                onClick={onClickTabItem}
                customData={customData}
              />
            )
          })}
        </ol>
        <div>
          {children.map(child => {
            if (child.props.id !== activeTabId) return undefined
            return child.props.children
          })}
        </div>
      </div>
    )
  }
}

export default Tabs
