/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { mediaQueryList } from "../helpers"

import Text from "./text"

const RollOverCard = ({ background, title, description }) => {
  return (
    <div
      sx={{
        [`${mediaQueryList()[2]}`]: {
          height: ["645px"],
          overflow: "hidden",

          ":hover": {
            "div:nth-of-type(1):after": {
              opacity: "0.88",
            },
            "div:nth-of-type(2)": {
              // TODO: get the real height of the Text container
              top: "-400px",
            },
          },
        },
      }}
    >
      <div
        sx={{
          width: "100%",
          background: background,
          backgroundSize: "cover !important",
          backgroundPosition: "center !important",
          height: ["300px", "300px", "645px"],
          [`${mediaQueryList()[2]}`]: {
            borderRadius: "5px",
            maxWidth: "400px",
            position: "relative",
            ":after": {
              backgroundColor: "rgba(19, 121, 157, 1)",
              opacity: 0,
              transition: "opacity 1s ease 0s",
              content: '""',
              display: "block",
              height: "100%",
              position: "absolute",
              width: "100%",
              top: 0,
              left: 0,
            },
          },
        }}
      ></div>

      <div
        sx={{
          position: "relative",
          [`${mediaQueryList()[2]}`]: {
            bottom: 0,
            padding: "32px",
            position: "relative",
            top: "-85px",
            height: "100%",
            width: "100%",
            transition: " top 1s ease 0s",
            zIndex: 2,
          },
        }}
      >
        <Text
          customStyle={{
            mb: 1,
            ":last-child": { mb: 0 },
            [`${mediaQueryList()[2]}`]: {
              color: "white",
            },
          }}
          type="h3"
        >
          {title}
        </Text>
        {description &&
          documentToReactComponents(description.json, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <Text
                  customStyle={{
                    mt: theme => [0, theme.spacing.vertical.sm],
                    [`${mediaQueryList()[2]}`]: {
                      color: "white",
                    },
                  }}
                >
                  {children}
                </Text>
              ),
            },
          })}
      </div>
    </div>
  )
}
RollOverCard.propTypes = {
  background: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  description: PropTypes.object,
}

export default RollOverCard
