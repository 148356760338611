/** @jsx jsx */
import { jsx } from "theme-ui"
import { Component } from "react"
import PropTypes from "prop-types"

import { mergeStyle } from "../../helpers"

import Text from "../text"

const tabStyle = {
  activeStyle: {
    borderBottom: theme => `3px solid ${theme.colors.primary}`,
  },
  titleTextStyle: {
    display: "inline",
    mb: 3,
  },
  listItemStyle: {
    display: "inline-block",
    cursor: "pointer",
    mr: 2,
    py: 2,
  },
}

class Tab extends Component {
  static propTypes = {
    activeTabId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    const { title, customData, onClick, id } = this.props
    onClick({ title, customData, id })
  }

  render() {
    const {
      onClick,
      props: { activeTabId, title, id },
    } = this

    return (
      <li
        sx={
          activeTabId === id
            ? mergeStyle(tabStyle.activeStyle, tabStyle.listItemStyle)
            : tabStyle.listItemStyle
        }
      >
        <button
          sx={{ bg: "transparent", border: "none", cursor: "pointer", p: 0 }}
          onClick={onClick}
        >
          <Text type="h4" customStyle={tabStyle.titleTextStyle}>
            {title}
          </Text>
        </button>
      </li>
    )
  }
}

export default Tab
