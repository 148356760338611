/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"
import get from "lodash/get"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Flex } from "@theme-ui/components"

import { findBySlug, renderLineBreak } from "../helpers"

import Carousel from "../components/carousel"
import Container from "../components/container"
import Column from "../components/column"
import CTA from "../components/cta"
import Hero from "../components/hero"
import Page from "../components/page"
import PageLink from "../components/page-link"
import ProjectUnique from "../components/project-unique"
import Row from "../components/row"
import SustainableConstruction from "../components/sustainable-construction"
import Text from "../components/text"
import ThreeRolloverCardPattern from "../components/three-rollover-card-pattern"

const AdvantagesPage = ({ data }) => {
  const renderHeroBladePattern = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-hero",
    })
    const heroHeights = [200, 300]

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: heroBladePatternData.mobileImage,
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
      ></Hero>
    )
  }
  const renderAdvantagesBladePattern = () => {
    const advantagesBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-pattern-advantages",
    })

    return (
      <Container customStyle={{ py: theme => [0, theme.spacing.vertical.lg] }}>
        <Row>
          {advantagesBladePatternData.contentCards.map(cardData => (
            <Column size={[12, 12, 3]} key={kebabCase(cardData.title)}>
              <Flex
                sx={{
                  justifyContent: ["unset", "unset", "center"],
                  mt: theme => [theme.spacing.vertical.sm, 0],
                  position: "relative",
                }}
              >
                <Flex
                  sx={{
                    flexDirection: "column",
                    paddingLeft: [5, 5, 0],
                    maxWidth: ["85%", "100%", 234],
                  }}
                >
                  <PageLink
                    link={`${data.contentfulPage.slug}#${kebabCase(
                      cardData.title
                    )}`}
                    target="_self"
                  >
                    <Text
                      type="h3"
                      customStyle={{
                        ":hover": {
                          color: theme => theme.colors.primary,
                        },
                      }}
                    >
                      {cardData.title}
                    </Text>
                  </PageLink>

                  {cardData.description &&
                    documentToReactComponents(cardData.description.json, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <Text
                            customStyle={{
                              mt: theme => [0, theme.spacing.vertical.sm],
                            }}
                          >
                            {children}
                          </Text>
                        ),
                      },
                    })}
                  <div
                    sx={{
                      height: 32,
                      width: [32, 32, "auto"],
                      textAlign: "center",
                      position: ["absolute", "absolute", "relative"],
                      left: [-10, -10, 0],
                      top: ["0", "0", "unset"],
                      mt: theme => [0, 0, theme.spacing.vertical.md],
                    }}
                  >
                    <img
                      sx={{ height: "100%" }}
                      src={cardData.mobileImage.file.url}
                      alt={cardData.mobileImage.title}
                    />
                  </div>
                </Flex>
              </Flex>
            </Column>
          ))}
        </Row>
      </Container>
    )
  }
  const renderWordClassDesignBladePattern = () => {
    const worldClassDesignBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-world-class-design-gallery",
    })
    const worldClassdesignCTAsBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "world-class-design-ctas",
    })
    const renderWorldClassDesignGallery = () => (
      <Carousel type="quaternary">
        {worldClassDesignBladePatternData.contentCards.map(contentCard => (
          <div key={kebabCase(contentCard.title)}>
            <img alt="" src={`${contentCard.desktopImage.file.url}`} />
          </div>
        ))}
      </Carousel>
    )
    const renderWorldClassDesignCTAs = () => {
      return (
        <Row customStyle={{ p: [0, 0, 6] }}>
          {worldClassdesignCTAsBladePatternData.contentCards.map(
            contentCard => (
              <Column
                customStyle={{
                  mt: theme => [theme.spacing.vertical.sm],
                }}
                size={[12, 12, 4]}
                key={kebabCase(contentCard.title)}
              >
                <Text type="h3">{contentCard.title}</Text>

                {contentCard.description &&
                  documentToReactComponents(contentCard.description.json, {
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => (
                        <Text
                          customStyle={{
                            mt: theme => [0, theme.spacing.vertical.sm],
                          }}
                        >
                          {children}
                        </Text>
                      ),
                    },
                  })}
                <div
                  sx={{
                    mt: theme => [
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.md,
                    ],
                  }}
                >
                  <CTA
                    link={get(
                      contentCard,
                      "ctaDestination.ctaDestination",
                      "/"
                    )}
                    type="secondary"
                    linkType="internal"
                  >
                    {contentCard.ctaTitle}
                  </CTA>
                </div>
              </Column>
            )
          )}
        </Row>
      )
    }

    return (
      <div
        id="custom-construction-world-class-design"
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
          py: theme => theme.spacing.vertical.lg,
        }}
      >
        <Container>
          <Row>
            <Column>
              <Text type="h2">
                {renderLineBreak(worldClassDesignBladePatternData.title)}
              </Text>
            </Column>
          </Row>
          <Row customStyle={{ p: [0, 0, 6] }}>
            <Column>{renderWorldClassDesignGallery()}</Column>
          </Row>
          {renderWorldClassDesignCTAs()}
        </Container>
      </div>
    )
  }
  const renderSustainableBladePattern = () => {
    const sustainableBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-sustainable-healthy-construction",
    })

    return (
      <div id="healthy-and-sustainable">
        <SustainableConstruction data={sustainableBladePatternData} />
      </div>
    )
  }
  const renderEachProjectIsUniqueBladePattern = () => {
    const eachProjectIsUniqueBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-each-project-is-unique",
    })

    return (
      <ProjectUnique data={eachProjectIsUniqueBladePatternData}></ProjectUnique>
    )
  }
  const renderFasterLowerCostBladePattern = () => {
    const fasterLowerCostBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-faster-lower-cost",
    })

    return (
      <div id="faster-lower-cost-and-more-reliable">
        <ThreeRolloverCardPattern data={fasterLowerCostBladePatternData} />
      </div>
    )
  }
  const renderPatentedBuildSystemBladePattern = () => {
    const renderPatentedBuildSystemData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-pantented-building-system",
    })

    return (
      <div id="built-to-last">
        <ThreeRolloverCardPattern data={renderPatentedBuildSystemData} />
      </div>
    )
  }

  return (
    <Page data={data.contentfulPage}>
      {renderHeroBladePattern()}
      {renderAdvantagesBladePattern()}
      {renderWordClassDesignBladePattern()}
      {renderFasterLowerCostBladePattern()}
      {renderSustainableBladePattern()}
      {renderPatentedBuildSystemBladePattern()}
      {renderEachProjectIsUniqueBladePattern()}
    </Page>
  )
}

export default AdvantagesPage

export const query = graphql`
  query advantagesPageQuery {
    contentfulPage(slug: { eq: "advantages" }) {
      ...PageData
      ...BladePatternData
    }
  }
`
