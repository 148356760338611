/** @jsx jsx */
import { jsx } from "theme-ui"
import kebabCase from "lodash/kebabCase"

import { getBackgroundList, renderLineBreak } from "../helpers"

import Container from "../components/container"
import Column from "../components/column"
import RollOverCard from "../components/rollover-card"
import Row from "../components/row"
import Text from "../components/text"

const ThreeRollOverCardPattern = ({ data }) => {
  return (
    <div
      sx={{
        py: theme => theme.spacing.vertical.lg,
      }}
    >
      <Container>
        <Row>
          <Text type="h2">{renderLineBreak(data.title)}</Text>
        </Row>
        <Row customStyle={{ pt: theme => theme.spacing.vertical.lg }}>
          {data.contentCards.map((contentCard, idx) => (
            <Column
              size={[12, 12, 4]}
              key={`${kebabCase(contentCard.title)}-${idx}`}
            >
              <RollOverCard
                background={getBackgroundList(contentCard)}
                title={contentCard.title}
                description={contentCard.description}
              />
            </Column>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default ThreeRollOverCardPattern
