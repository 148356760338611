/** @jsx jsx */
import { jsx } from "theme-ui"
import { Component } from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import kebabCase from "lodash/kebabCase"
import PropTypes from "prop-types"

import { renderLineBreak } from "../helpers"

import Container from "./container"
import Column from "./column"
import Row from "./row"
import Tabs from "./tabs/index"
import Text from "./text"

class SustainableConstruction extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      currentImage: this.props.data.contentCards[0].desktopImage.file.url,
    }
  }

  onTabChange = tab => {
    const { desktopImage } = tab.customData

    this.setState({
      currentImage: desktopImage.file.url,
    })
  }
  renderTabs() {
    const { contentCards } = this.props.data

    return (
      <Tabs onTabChange={this.onTabChange}>
        {contentCards.map((contentCard, idx) => (
          <div
            title={`${contentCard.title}`}
            id={`${kebabCase(contentCard.title)}`}
            customData={contentCard}
            key={`${kebabCase(contentCard.title)}-${idx}`}
          >
            {contentCard.description &&
              documentToReactComponents(contentCard.description.json, {
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => (
                    <Text
                      customStyle={{
                        mt: theme => [0, theme.spacing.vertical.sm],
                      }}
                    >
                      {children}
                    </Text>
                  ),
                },
              })}
          </div>
        ))}
      </Tabs>
    )
  }
  renderImage() {
    const {
      state: { currentImage },
    } = this

    return (
      <div>
        <img alt="" sx={{ width: "100%" }} src={currentImage} />
      </div>
    )
  }
  render() {
    const { title } = this.props.data

    return (
      <div
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
          py: theme => theme.spacing.vertical.lg,
        }}
      >
        <Container>
          <Row>
            <Column>
              <Text type="h2">{renderLineBreak(title)}</Text>
            </Column>
          </Row>
          <Row
            customStyle={{
              flexDirection: ["column", "column", "row-reverse"],
              mt: theme => [theme.spacing.vertical.lg],
            }}
          >
            <Column size={[12, 12, 8]}>{this.renderImage()}</Column>
            <Column size={[12, 12, 4]}>{this.renderTabs()}</Column>
          </Row>
        </Container>
      </div>
    )
  }
}

export default SustainableConstruction
